<template>
  <div :id="id"></div>
</template>

<script>
import * as d3 from "d3";
import $ from "jquery";
export default {
  props: ["id"],
  data() {
    return {};
  },
  methods: {
    async playOut() {
      console.log('退出')
    },
    //核心库
    CustomTreeChart(
      data,
      {
        marginTop = 40, // the top margin, in pixels
        marginRight = 90, // the right margin, in pixels
        marginBottom = 40, // the bottom margin, in pixels
        marginLeft = 90, // the left margin, in pixels
        width = 960, // the outer width of the chart, in pixels
        height = 400, // the outer height of the chart, in pixels
        multiple = 1, // 默认1，从主节点的边缘出发
        duration = 1000, //动画持续时长
        delay = 500, //元素之间间隔时长
        ease = "easeBack",
        img = '',
        lineWidth = 300,
        nodeLength = 100, // 起点宽高
        branchNodeWidth = 220,  // 分支宽度
        branchNodeHeight = 100, // 分支高度
      } = {}
    ) {
        const innerWidth = width - marginLeft - marginRight
        const innerHeight = height - marginTop - marginBottom
        const svg = d3.create("svg")
            .attr("width", width)
            .attr("height", height)
        $("#" + this.id).html(svg.node());

        this.svg = svg.append("g").attr("transform", "translate("+ marginLeft + "," + marginTop + ")");
        this.treemap = d3.tree().size([innerHeight, innerWidth]);
        let root = d3.hierarchy(data, function(d) { return d.children; });
        root.x0 = innerHeight / 2;
        root.y0 = 0;
        this.root = root
        this.duration = duration
        this.delay = delay
        this.multiple = typeof multiple === 'number' &&  multiple > 0 ? multiple : 1
        this.ease = ease
        this.img = img
        this.lineWidth = lineWidth
        this.nodeLength = nodeLength
        this.branchNodeWidth = branchNodeWidth
        this.branchNodeHeight = branchNodeHeight
        console.log(nodeLength)
        this.update(root);
    },
    update(source) {
        const _that = this
        // Assigns the x and y position for the nodes
        let treeData = this.treemap(this.root);
        // Compute the new tree layout.
        let nodes = treeData.descendants(),
            links = treeData.descendants().slice(1);
        // Normalize for fixed-depth.
        nodes.forEach(function(d){
                d.y = d.depth * _that.lineWidth + _that.nodeLength / _that.multiple
            });

        // 添加根节点
        this.svg.selectAll('g.nodeMain')
            .data(nodes.slice(0, 1), function(d) {return d.id})
            .enter().append('g').attr('class', 'nodeMain')
            .attr("transform", function(d) {
               return "translate(" + d.y0 + "," + d.x0 + ")";
            })
            .attr('opacity', '0')
            .append('foreignObject')
            .attr('width', _that.nodeLength)
            .attr('height', _that.nodeLength)
            .attr('transform', `translate(0, -${ _that.nodeLength / 2 })`)
            // .attr('transform', `translate(-${_that.nodeLength / 2}, -${ _that.nodeLength / 2 })`)
            .append('xhtml:div')
            .attr('class', 'main')
            .style('width', '100%')
            .style('height', '100%')
            .style('border-radius', '50%')
            .style('background', 'radial-gradient(circle, #3F230BFF 50%, #664F3CFF 80%, #FFC97B 100%)')
            // .style('display', 'flex')
            // .style('justify-content', 'center')
            // .style('align-items', 'center')
            // .style('text-align', 'center')
            // .style('line-height', 1)
            // .style('font-size', '20px')
            // .style('color', '#FE9805')
            .html((i) => i.data.name)
            .append('img')
            .attr('src', _that.img)
            .style('height', '100%')
            .style('width', 'auto')
            .style('border-radius', '50%')
            .style('overflow', 'hidden')
        // 根节点动画
        d3.select('.nodeMain').transition()
            .ease(d3[_that.ease + "Out"])
            .duration(_that.duration)
            .attr('opacity', '1');
       
        // 添加&&更新分支节点
        let node = this.svg.selectAll('g.node')
            .data(nodes.slice(1), function(d) { return d.id });
        // Enter any new modes at the parent's previous position.
        let nodeEnter = node.enter().append('g')
            .attr('class', 'node')
            .attr("transform", function(d) {
                return "translate(" + source.y0 + "," + source.x0 + ")";
            })
            .attr('opacity', '0')
            nodeEnter.append('foreignObject')
            .attr('width', _that.branchNodeWidth)
            .attr('height', _that.branchNodeHeight)
            .attr('transform', `translate(-${_that.branchNodeWidth / 2}, -${_that.branchNodeHeight / 2})`)
            .append('xhtml:div')
            .attr('class', 'branch')
            .style('width', '100%')
            .style('height', '100%')
            .style('color', '#fff')
            .style('font-size', '16px')
            .style('display', 'flex')
            .style('align-items', 'center')
            .style('justify-content', 'center')
            .append('span')
            .style('border-radius', `${_that.branchNodeHeight / 2}px`)
            .style('border', '1px solid #FE9805')
            .style('padding', '12px 24px')
            .style('text-align', 'center')
            .style('background', 'rgb(68 38 12)')
            .style('color', '#FE9805')
            .html((i) => i.data.name)
        // UPDATE
        let nodeUpdate = nodeEnter.merge(node);
        // Transition to the proper position for the node
        nodeUpdate.transition()
            .duration(_that.duration)
            .ease(d3[_that.ease + "Out"])
            .delay(_that.delay)
            .attr("transform", function(d) { 
                return `translate(${d.y}, ${d.x})`;
            })
            .attr('opacity', '1');
         // ****************** text section ***************************
        // let text = this.svg.selectAll('path.lineText').data(nodes.slice(1), function(d) { return d.id })
        // let textEnter = text.enter().append('text')
        // .attr('class', 'lineText')
        // .text((d) => d.data.name)
        // .attr('font-size', '14px')
        // .attr('fill', '#fff')
        // .attr("opacity", "0")
        // .attr("transform", function(d) { 
        //         return `translate(${((d.y + d.parent.y) / 2) - 100}, ${(d.x + d.parent.x) / 2})`;
        //     })
        // let textUpdate = textEnter.merge(text);
        // textUpdate.transition()
        //     .duration(_that.duration)
        //     .ease(d3[_that.ease + "Out"])
        //     .delay(_that.delay)
        //     .attr('opacity', '1');
        // ****************** links section ***************************
        // 添加树线
        let link = this.svg.selectAll('path.link')
            .data(links, function(d) { return d.id; });

        // Enter any new links at the parent's previous position.
        let linkEnter = link.enter().insert('path', "g")
            .attr("class", "link")
            .attr('d', function(d){
                let o = {x: source.x0, y: source.y0}
                return _that.diagonal(o, o)
            })
            .attr('fill', 'none')
            .attr('stroke-width', 2)
            .attr('stroke', '#FE9805')
            .attr('stroke-dasharray', '5,5')
        // UPDATE
        let linkUpdate = linkEnter.merge(link);

        // Transition back to the parent element position
        linkUpdate.transition()
            .duration(_that.duration)
            .ease(d3[_that.ease + "Out"])
            .delay(_that.delay)
            .attr('d', function(d){ return _that.diagonal(d, d.parent) });
    },
    diagonal(s, d) {
        let path = `M ${s.y} ${s.x}
                C ${(s.y + d.y) / 2} ${s.x},
                ${(s.y + d.y) / 2} ${d.x},
                ${d.y} ${d.x}`

        return path
    },
  },
};
</script>
